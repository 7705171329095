import React from "react";
import Header from "../../../components/header";
import HeroBanner from "../../../components/HeroBanner";
import Layout from "../../../components/layout";
import { Message } from "../../../lib/getMessage";
import WithProviders from "../../../components/WithProviders";
import ProductWithImage from "../../../components/ProductWithImage";
import AnalyticsLaptopImg from "../../images/analytics-desktop.png";
import AnalyticsLaptopImg2 from "../../images/analytics-desktop@2.png";

import AnalyticsNewProduct from "../../images/analytics-new-product.png";
import AnalyticsNewProduct2 from "../../images/analytics-new-product@2x.png";
import AnalyticsNewProduct3 from "../../images/analytics-new-product@3x.png";

import AnalyticsWorkforceManagement from "../../images/analytics-workforce-management.png";
import AnalyticsWorkforceManagemen2 from "../../images/analytics-workforce-management@2x.png";
import AnalyticsWorkforceManagemen3 from "../../images/analytics-workforce-management@3x.png";

import AnalyticsSkuLevel from "../../images/analytics-sku-level.png";
import AnalyticsSkuLevel2 from "../../images/analytics-sku-level@2x.png";
import AnalyticsSkuLevel3 from "../../images/analytics-sku-level@3x.png";

import "./style.css";
const Analytics = (props) => {
  const { language, updateLanguage, direction } = props;
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      direction={direction}
      showFooterForm
      language={language}
      location={props.location}
    >
      <div>
        <Header
          current="product"
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <div className="pr-analytics">
          <HeroBanner
            title={<Message dictKey="products.analytics.title" />}
            className={`analytics-banner ${
              isDirectionRTL && "analytics-banner-rtl"
            }`}
            isDirectionRTL={isDirectionRTL}
          />
          <section className="hero-img-banner">
            <img
              src={AnalyticsLaptopImg}
              srcSet={`${AnalyticsLaptopImg2} 2x`}
              alt="analytics-media"
              loading="lazy"
            />
          </section>
          <section className="pr-analtics-rprt">
            <ProductWithImage
              name="new-product"
              className="new-product"
              heading={
                <Message dictKey="products.analytics.newproduct.heading" />
              }
              description={[
                {
                  type: "p",
                  val: (
                    <Message dictKey="products.analytics.newproduct.description1" />
                  ),
                  id: 1,
                },
                {
                  type: "p",
                  val: (
                    <Message dictKey="products.analytics.newproduct.description2" />
                  ),
                  id: 2,
                },
              ]}
              image={{
                normal: AnalyticsNewProduct,
                xx: AnalyticsNewProduct2,
                xxx: AnalyticsNewProduct3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
            <ProductWithImage
              name="workforce-management"
              className="workforce-management"
              heading={
                <Message dictKey="products.analytics.workforcemanagement.heading" />
              }
              description={[
                {
                  type: "p",
                  val: (
                    <Message dictKey="products.analytics.workforcemanagement.description1" />
                  ),
                  id: 1,
                },
                {
                  type: "p",
                  val: (
                    <Message dictKey="products.analytics.workforcemanagement.description2" />
                  ),
                  id: 2,
                },
              ]}
              image={{
                normal: AnalyticsWorkforceManagement,
                xx: AnalyticsWorkforceManagemen2,
                xxx: AnalyticsWorkforceManagemen3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
            <ProductWithImage
              name="sku-level-economics"
              className="sku-level-economics"
              heading={
                <Message dictKey="products.analytics.skueconomics.heading" />
              }
              description={[
                {
                  type: "p",
                  val: (
                    <Message dictKey="products.analytics.skueconomics.description1" />
                  ),
                  id: 1,
                },
                {
                  type: "p",
                  val: (
                    <Message dictKey="products.analytics.skueconomics.description2" />
                  ),
                  id: 2,
                },
              ]}
              image={{
                normal: AnalyticsSkuLevel,
                xx: AnalyticsSkuLevel2,
                xxx: AnalyticsSkuLevel3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(Analytics);
